import React, {useMemo} from "react";
import "./style.css";
import Icon1 from "./Assets/icon1.png";
import Icon2 from "./Assets/icon2.png";
import Icon3 from "./Assets/icon3.png";
import ParticlesAnimation from "../../../components/Particles animation/ParticlesAnimation";
import { Link } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import SpinnerComponent from "../../../components/Spinner/SpinnerComponent";
import NotFound from "../../../components/Not Found/NotFound";

const PRODUCTS = gql`
query GetRecognitions {
  recognitions {
    data {
      id
      attributes {
        Name
        Link
        Logo {
          data {
            attributes {
              url
            }
          }
        }
      }
    }
  }
}
`;

function OurPartners() {
  const { loading, error, data: recognitions } = useQuery(PRODUCTS);
  if (loading) return <SpinnerComponent />;
  if (error) return <NotFound />;

  return (
    <>
      <div style={{position:'relative', background: '#f0f0f0'}} className="container-fluid mt-5 pb-3 partners-section">
        <div className="container py-5">
          <div className="row">

            <div className="col-md-6">
                <div className="row my-4 justify-content-center first-title">Our Partners</div>
                <div className="row justify-content-center left-box">
                  <div className="image-container">
                    <div className="image-cover"></div>
                    <img src={Icon1} className="m-3 img-fluid" />
                  </div>
                  <div className="image-container">
                    <div className="image-cover"></div>
                    <img src={Icon2} className="m-3 img-fluid" />
                  </div>
                  <div className="image-container">
                    <div className="image-cover"></div>
                    <img src={Icon3} className="m-3 img-fluid" />
                  </div>
                </div>
            </div>

            <div className="col-md-6">
                <div className="row my-4 justify-content-center first-title">Recognition</div>
                <div className="row justify-content-center">
                  {(recognitions?.recognitions?.data || []).map(recognition =>
                    <a href={recognition?.attributes?.Link}>
                      <div className="image-container">
                        <div className="image-cover"></div>
                        <img src={recognition?.attributes?.Logo?.data?.attributes?.url} className="m-3 img-fluid" />
                      </div>
                    </a>
                  )}
                </div>
            </div>

          </div>

          <div className="row justify-content-center mt-4">
            <p>Find out how DSL is used by industry and government ( <a href="#solution_section" style={{ textDecoration: "none" }}> jump to solutions </a>)</p>
          </div>

        </div>
      </div>
    </>
  );
}

export default OurPartners;

import React from "react";
import "./style.css";
import ParticlesAnimation from "../../../components/Particles animation/ParticlesAnimation";

function ProductVideo() {
  return (
    <>
      <div
        style={{position:'relative'}}
        className="container-fluid video_section py-5"
      >
        <ParticlesAnimation id='p-works' />
        <div className="row py-5">
          <div className="col-md-7 px-5">
            <iframe className="iframe-video" width="100%" src="https://www.youtube.com/embed/4KIMvobZWO8" frameborder="0" allowfullscreen></iframe>
          </div>
          <div className="col-md-5 px-5" style={{color:'#fff', textAlign: 'left'}}>

            <h2 className="py-2">Linking internal and external data for a holistic view is challenging.</h2>
            <p>Organizations produce internal data that is often fragmented and incomplete, making it difficult to link to public sources.</p>
            <p>As a result, using AI models can be unreliable.</p>
            <br />
            <h2 className="py-2">Introducing DeepSearch Labs</h2>
            <p>Our AI search engine swiftly analyzes millions of sources, revealing patterns through a map of information.</p> 
            <p>DSL contextualizes this map, using Large Language Models, without producing any hallucinations.</p>
            <p>We have prioritized your privacy and control above everything.</p>
            
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductVideo;

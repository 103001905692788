import React, { useEffect } from "react";
import "./style.css";
import { useLocation, Link, NavLink } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import SpinnerComponent from "../../components/Spinner/SpinnerComponent";
import NotFound from "../../components/Not Found/NotFound";
import { Helmet } from "react-helmet-async";
import FAQs from "../../website sections/Home Page Sections/FAQs/FAQs";
import ParticlesAnimation from "../../components/Particles animation/ParticlesAnimation";

// (where: {Category: "Case Studies"})
const CATEGORY_WITH_ARTICLES = gql`
  query GetCategoryWithArticles($id: ID!) {
    category(id : $id) { 
      data {
        id
        attributes {
          Category
          articles {
            data {
              id
              attributes {
                Title
                Date
                Description
                CoverImage {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }

        }
      }
    }
  }
`;

function FaqsPage() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const { loading, error, data: caseStudies } = useQuery(CATEGORY_WITH_ARTICLES, { variables: { id: 10 } });
  if (loading) return <SpinnerComponent />;
  if (error) return <NotFound />;

  const title = "Solutions";
  const description = "At DeepSearch Labs, our powerful AI search engine provides game-changing insights for organizations across industries - from finance to consulting and beyond. Click on any solution below to learn how our platform can give you a competitive edge in your market.";

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
        return text;
    } else {
        return text.substring(0, maxLength) + '...';
    }
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta
          name="description"
          content={description}
        />
        <link rel="canonical" href={pathname} />
      </Helmet>

      <FAQs align="center" />
      
      <div className="container mt-5 case-studies-section">
      {(caseStudies?.category?.data?.attributes?.articles?.data || []).length > 0 && 
        <h1 className="title m-4">Case Studies</h1>
      }
        <ParticlesAnimation id='p-prod' />
        <div className="row justify-content-center">
          
            {(caseStudies?.category?.data?.attributes?.articles?.data || []).map(article =>
              <div className="col-md-4 my-3 text-left" key={article.id}>
                <img
                  className="img-rounded blog-imgs"
                  src={article.attributes.CoverImage?.data?.attributes?.url}
                  alt="Card cap"
                />
                <p className="category-text">{caseStudies?.category?.data?.attributes?.Category || "Case Studies"}</p>
                <p className="article-tilte">{article.attributes.Title}</p>
                <p className="article-date">{article.attributes.Date}</p>
                <p className="article-desct">{truncateText(article.attributes.Description, 100)}</p>
                <Link to={"/posts/" + article.id} style={{ textDecoration: "none" }}>
                  <p className="article-read-more">
                    Read more
                  </p>
                </Link>
              </div>
            )}
          
        </div>
      </div>

      <div style={{ padding:'8em 0',display:'flex', flexDirection:'column', alignItems:'center'}}>
        <div style={{background: 'linear-gradient(90.51deg, #00022a 0%, #92140c 115%)',
          fontSize:'25px',
          '-webkit-background-clip': 'text',
          WebkitTextFillColor: 'transparent'}}>
          To know more about our upcoming projects contact us below
        </div>
        <div style={{height:'3.5em'}}/>
        <NavLink
          className= "bttn"
          style={{textDecoration:'none', borderRadius:'15px', minWidth:'150px', minHeight:'50px'}}
          to="/contact"
        >
          Contact us
        </NavLink>
        <div style={{height:'2em'}}/>
      </div>

    </>
  );
}

export default FaqsPage;

import React, { useEffect } from "react";
import "./style.css";
import Team from "../../website sections/Home Page Sections/Team and Advisors/Team";
import { Helmet } from "react-helmet-async";
import Advisors from "../../website sections/Home Page Sections/Team and Advisors/Advisors";

function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>About</title>
        <meta
          name="description"
          content="DeepSearch labs is a deeptech company, focused on creating specialized and bespoke intelligent search engines for industry. We are where insight meets search engines.
          We collect data from the World Wide Web, label, organize, and de-noise it, all without human interaction. 
          We simplify the world by helping clients understand what information matters, we summarize the information into reports and identify trends, their stages of development and predict its future impact while highlighting winners and losers.
          Our output enhances decision making, idea generation, research, product creation, and risk mitigation. We remove the expense of data-sets from our clients and allow them to go straight to the insight."
        />
        <link rel="canonical" href="/about" />
      </Helmet>
      <div>
        <div className="container mt-5 pt-5">
          <div className="row justify-content-center">
            <div className="col-10 col-md-6">
              <h1 className="about-page-main-title">About Us</h1>
            </div>
          </div>
        </div>
        <div className="container-fluid story_section">
          <div className="row justify-content-center py-5 my-5">
            <div className="col-8 text-left text-light py-5">
              <h1 className="my-3 about-page-story-title">Our Story</h1>
              <p className="about-page-parag">
                DeepSearch labs is a deeptech company, focused on creating
                specialized and bespoke intelligent search engines for industry.
                We are where insight meets search engines.
              </p>
              <p className="about-page-parag">
                Research professionals spend roughly 40% of their time on search
                engines to find relevant answers to their questions. However,
                with the continual improvements in technology and our knowledge
                we’re able to compress the work of 100 analysts over 6-8 months,
                into minutes.
              </p>
              <p className="about-page-parag">
                We collect data from the World Wide Web, label, organize, and
                de-noise it, all without human interaction. We simplify the
                world by helping clients understand what information matters, we
                summarize the information into reports and identify trends,
                their stages of development and predict its future impact while
                highlighting winners and losers.
              </p>
              <p className="about-page-parag">
                Our output enhances decision making, idea generation, research,
                product creation, and risk mitigation. We remove the expense of
                data-sets from our clients and allow them to go straight to the
                insight.
              </p>
              <p className="about-page-parag">
                For more information and to test our next generation search
                engine, please do not hesitate to contact us.
              </p>
            </div>
          </div>
        </div>
        <Team />
        {/* <Advisors /> */}
      </div>
    </>
  );
}

export default About;

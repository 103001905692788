import React, { useEffect, useState, useRef, useCallback } from 'react'
import linkedinAuthor from './assets/linkedinAuthor.svg'
import HoverCard from './HoverCard'
import { useMediaQuery } from "react-responsive";

export default function ProfileCard(props) {
    const { linkedin, bio, jobTitle, name } = props.attributes
    const pic = props.attributes.picture.data.attributes.url
    const [isHoverCard, setisHoverCard] = useState(false)

    const ref = useRef()
    const isPc = useMediaQuery({
        minWidth: 1024
    })
    let handleEnter = () => setisHoverCard(true)
    let handleLeave = () => setisHoverCard(false)


    useEffect(() => {
        if (ref.current) {
            let el = ref.current
            el.addEventListener('mouseenter', handleEnter)
            el.addEventListener('mouseleave', handleLeave)
            return () => {
                el.removeEventListener('mouseenter', handleEnter)
                el.removeEventListener('mouseleave', handleLeave)
            }
        }
    }, [ref.current])

    return (
        <div ref={ref} className='profile-card' style={{ display: 'flex', flexDirection: 'column' }}>
            {isPc && <HoverCard teamInfo={props.attributes} opacity={isHoverCard ? 1 : 0} />}
            <div className='sqr-img-container'
                onClick={() => {
                    props.actions.updateModal();
                    props.actions.openModal();
                }}>
                <img src={pic} />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start', paddingTop: '1em' }}>
                <div style={{ width: 'fit-content', margin: '0' }}>
                    <div color='#00022a' style={{ fontWeight: 'bold', textAlign: 'left' }}>{name}</div>
                    <div color='#00022a' style={{ textAlign: 'left' }}>{jobTitle}</div>
                </div>
                <a className='linkedin-link' target="_blank"
                    rel="noopener noreferrer" href={linkedin}>
                    <img width='32px' height='32px' src={linkedinAuthor} />
                </a>
            </div>
        </div>

    )
}

import React, { useRef, useState } from "react";
import "./style.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import emailjs from "@emailjs/browser";

function ContactForm() {
  const form = useRef();
  const [formFields, setFormFields] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  //toast
  const [userMsg, setUserMsg] = useState({ msg: "", msgColor: "green" });

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_n8s6zs7",
        "template_a4vtalw",
        form.current,
        "2yGm5NEOmgzeGN4ub"
      )
      .then(
        (result) => {
          console.log(result.text);
          setFormFields({ name: "", email: "", subject: "", message: "" });
          setUserMsg({
            ...userMsg,
            msg: "Your message have been sent successfully",
          });
        },
        (error) => {
          console.log(error.text);
          setUserMsg({ msg: "Error, try again", msgColor: "red" });
        }
      );
    setUserMsg({
      ...userMsg,
      msg: "",
    });
  };
  return (
    <div className="form_section p-2 p-md-5">
      <p className="mt-5 mb-2 form_head_title">Contact Us</p>
      <Form
        className="mb-5 mx-5 bg-transparent"
        ref={form}
        onSubmit={sendEmail}
      >
        <Form.Group className="text-left mb-3" controlId="formBasicName">
          <Form.Label className="text-light">Name</Form.Label>
          <Form.Control
            className="form_text_input"
            type="text"
            placeholder="Enter Your Name"
            name="from_name"
            required
            value={formFields.name}
            onChange={(e) => setFormFields(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="text-left mb-3" controlId="formBasicEmail">
          <Form.Label className="text-light">Email</Form.Label>
          <Form.Control
            className="form_text_input"
            type="email"
            placeholder="Enter Your Email"
            name="email_adress"
            required
            value={formFields.email}
            onChange={(e) => setFormFields(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="text-left mb-3" controlId="formBasicSubjectl">
          <Form.Label className="text-light">Subject</Form.Label>
          <Form.Control
            className="form_text_input"
            type="text"
            placeholder="Enter Your Subject"
            name="contact_subject"
            required
            value={formFields.subject}
            onChange={(e) => setFormFields(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="text-left mb-3" controlId="formBasicMessage">
          <Form.Label className="text-light">Message</Form.Label>
          <textarea
            className="form-control form_text_input"
            rows="4"
            placeholder="Enter Your Message"
            name="message"
            required
            value={formFields.message}
            onChange={(e) => setFormFields(e.target.value)}
          ></textarea>
        </Form.Group>

        <Button
          className="button_send_form px-5"
          variant="danger"
          type="submit"
          value="Send"
        >
          Send
        </Button>
        {userMsg.msg === "" ? null : (
          <h6 className="mt-3" style={{ color: `${userMsg.msgColor}` }}>
            {userMsg.msg}
          </h6>
        )}
      </Form>
    </div>
  );
}

export default ContactForm;

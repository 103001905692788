import React, {useMemo} from "react";
import "./style.css";
import Icon1 from "./Assets/icon1.svg";
import Icon2 from "./Assets/icon2.svg";
import Icon3 from "./Assets/icon3.svg";
import Icon4 from "./Assets/icon4.svg";
import Icon5 from "./Assets/icon5.svg";
import Icon6 from "./Assets/icon6.svg";
import ParticlesAnimation from "../../../components/Particles animation/ParticlesAnimation";
function WhyDeepSearchLabs() {
  return (
    <>
      <div style={{position:'relative'}}  className="container-fluid mt-5 why-dsl-section">
        <ParticlesAnimation id='p-cases' />
        <div className="container py-5">
          <div className="row justify-content-start">
            <div className="col-12 col-md-9 col-lg-10 d-flex flex-column align-items-start p-0">
              <p className="first-title">BENEFITS</p>
              <h1 className="second-title text-left">Why DeepSearch Labs?</h1>
            </div>
          </div>
          <div className="row mt-5 pt-3 justify-content-center">
            <div className="col-md-4">
              <div className="card bg-transparent border-0 py-4 mt-2 mt-md-0">
                <img
                  className="card-img-top-left icons"
                  src={Icon4}
                  alt="Card cap"
                />
                <div className="card-body text-left">
                  <h5 className="card-title py-4">
                    Uncover real-time insights swiftly, avoid blind spots.
                  </h5>
                  <p className="card-text text-justify">
                    DeepSearch Lab's AI swiftly analyzes millions of data sources in real time, visualizing insights into a map for query with our AI language model.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card bg-transparent border-0 py-4 mt-2 mt-md-0">
                <img
                  className="card-img-top-left icons"
                  src={Icon5}
                  alt="Card cap"
                />
                <div className="card-body text-left">
                  <h5 className="card-title py-4">
                    Quickly Customize the results
                  </h5>
                  <p className="card-text text-justify">
                    Customize your search experience with our engine. Easily refine results with advanced filters like date range, regions, sectors, sources, and keywords.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card bg-transparent border-0 py-4 mt-2 mt-md-0">
                <img
                  className="card-img-top-left icons"
                  src={Icon1}
                  alt="Card cap"
                />
                <div className="card-body text-left">
                  <h5 className="card-title py-4">
                    Ease privacy concerns
                  </h5>
                  <p className="card-text text-justify">
                    Protect your company’s data and privacy with the highest industry standards.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card bg-transparent border-0 py-4 mt-2 mt-md-0">
                <img
                  className="card-img-top-left icons"
                  src={Icon6}
                  alt="Card cap"
                />
                <div className="card-body text-left">
                  <h5 className="card-title py-4">
                    Gain unbiased findings
                  </h5>
                  <p className="card-text text-justify">
                    DeepSearch Labs relies on impartial AI/NLP and reliable sources, avoiding human biases that can skew results.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card bg-transparent border-0 py-4 mt-2 mt-md-0">
                <img
                  className="card-img-top-left icons"
                  src={Icon3}
                  alt="Card cap"
                />
                <div className="card-body text-left">
                  <h5 className="card-title py-4">
                    Turn data into a report
                  </h5>
                  <p className="card-text text-justify">
                    DeepSearch Labs can turn the map of information into AI generated insightful market research reports. Our reports excel at research depth, writing quality, translation, false positives avoidance.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card bg-transparent border-0 py-4 mt-2 mt-md-0">
                <img
                  className="card-img-top-left icons"
                  src={Icon2}
                  alt="Card cap"
                />
                <div className="card-body text-left">
                  <h5 className="card-title py-4">
                    Save time
                  </h5>
                  <p className="card-text text-justify">
                    Let our AI technology do the work for you. Spend less time on time-consuming, manual tasks and more time on what you do best: Analysis and Decision Making.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WhyDeepSearchLabs;

import React, { useEffect } from "react";
import "./style.css";
import dot from "./Assets/Ellipse.svg";
import { useLocation, useParams } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import RelatedPosts from "../../components/Related Posts/RelatedPosts";
import Newsletter from "../../components/Newsletter/Newsletter";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { FacebookIcon, TwitterIcon } from "react-share";
import shareLink from "./Assets/shareLink.svg";
import linkedin from "./Assets/linkedinAuthor.svg";
import SpinnerComponent from "../../components/Spinner/SpinnerComponent";
import NotFound from "../../components/Not Found/NotFound";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import UseCases from "../../website sections/Home Page Sections/Use Cases/UseCases";
import Solutions from "../../website sections/Home Page Sections/Solutions/Solutions";
import { Link } from "react-router-dom";


const POST = gql`
  query GetSolution($id: ID!) {
    solution(id: $id) {
      data {
        id
        attributes {
          name
          desc
          icon {
            data {
              attributes {
                url
              }
            }
          }
          
          articles {
            data {
              id
              attributes {
                Title
                Date
                Description
                CoverImage {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                
                category {
                  data {
                    attributes {
                      Category
                    }
                  }
                }

              }
            }
          }

        }
      }
    }
  }
`;

function Solution() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const { id } = useParams();
  const { loading, error, data } = useQuery(POST, { variables: { id: id } });

  if (loading) return <SpinnerComponent />;
  if (error) return <NotFound />;
  // console.log("data: ", data)
  const desc = { __html: `${data.solution.data.attributes.desc}` };

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
        return text;
    } else {
        return text.substring(0, maxLength) + '...';
    }
  }

  const articlesData = data.solution?.data?.attributes?.articles?.data || [];
  // Sort the array by Date
  const sortedArticles = articlesData.slice().sort((a, b) => {
      const dateA = new Date(a.attributes.Date);
      const dateB = new Date(b.attributes.Date);
      return dateB - dateA;
  });

  return (
    <>
      <Helmet>
        <title>{data.solution.data.attributes.name}</title>
        <meta
          name="description"
          content={data.solution.data.attributes.desc}
        />
        <link rel="canonical" href={pathname} />
      </Helmet>

      <main className="container-fluid m-0 py-5 single-solution">
        <div className="container">

          <section className="row mt-5">
            <div class="col-md-12 text-center">
              <img
                className="solution-logo m-4"
                src={data.solution.data.attributes.icon?.data?.attributes?.url}
                alt="Card cap"
              />
              <h1>{data.solution.data.attributes.name}</h1>
            </div>
            <section
              className="p-5 text-left m-auto"
              dangerouslySetInnerHTML={desc}
            />
          </section>

          {sortedArticles.length > 0 && <h1 className="p-4 text-center">Case Studies</h1 >}
          <div className="row case_study_blogs">
            {sortedArticles.map(article => (
              <div className="col-md-4 my-3 text-left" key={article.id}>
                <img
                  className="img-rounded blog-imgs"
                  src={article.attributes.CoverImage?.data?.attributes?.url}
                  alt="Card cap"
                />
                <p className="category-text">{article.attributes.Title}</p>
                <p className="article-tilte">{article.attributes.category.data.attributes.Category}</p>
                <p className="article-date">{article.attributes.Date}</p>
                <p className="article-desct">{truncateText(article.attributes.Description, 100)}</p>
                <Link to={"/posts/" + article.id} style={{ textDecoration: "none" }}>
                  <p className="article-read-more">
                    Read more
                  </p>
                </Link>
              </div>
            ))}
          </div>

        </div>
      </main>

      <Solutions />

    </>
  );
}

export default Solution;

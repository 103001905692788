import React, {useState} from "react";
import "./style.css";
import PostCard from "../../../components/Post Card/PostCard";
import Newsletter from "../../../components/Newsletter/Newsletter";
import { Link } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import SpinnerComponent from "../../../components/Spinner/SpinnerComponent";
import NotFound from "../../../components/Not Found/NotFound";
import ParticlesAnimation from "../../../components/Particles animation/ParticlesAnimation";

const CATEGORIES_WITH_ARTICLES = gql`
  query GetCategoriesWithArticles {
    categories {
      data {
        id
        attributes {
          Category
          articles {
            data {
              id
              attributes {
                Title
                Date
                Description
                CoverImage {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }

        }
      }
    }
  }
`;


function Blog({fromHomePage = false, align = 'left'}) {
  const { loading, error, data } = useQuery(CATEGORIES_WITH_ARTICLES);
  const [selectedCategory, setSelectedCategory] = useState(null);

  if (loading) return <SpinnerComponent />;
  if (error) return <NotFound />;

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
        return text;
    } else {
        return text.substring(0, maxLength) + '...';
    }
  }

  const sortedCategoryArticles = (category) => {
    const categoryArticlesData = category.attributes?.articles?.data || [];
    // Sort the array by Date
    const sortedCategoryArticlesData = categoryArticlesData.slice().sort((a, b) => {
        const dateA = new Date(a.attributes.Date);
        const dateB = new Date(b.attributes.Date);
        return dateB - dateA;
    });
    return sortedCategoryArticlesData;
  }

  const sortedCategories = (selectedCategory) => {
    const categoryArticlesData = selectedCategory?.attributes?.articles?.data || [];
    // Sort the array by Date
    const sortedCategoryArticlesData = categoryArticlesData.slice().sort((a, b) => {
        const dateA = new Date(a.attributes.Date);
        const dateB = new Date(b.attributes.Date);
        return dateB - dateA;
    });
    return sortedCategoryArticlesData;
  }

  

  return (
    <section style={{position:'relative'}} id='blog-section' className="container my-5 py-5">
      <ParticlesAnimation id='p-blog' />
      <div className="container pb-2 _blogs">
        <div className="row justify-content-start">
          <div className={"col-12 p-0 text-"+align}>
            <p className="first-title">DEEPSEARCH DIGEST</p>
            <h1 className="second-title">Updates, Innovations, and Insights</h1>
          </div>
        </div>
        <div className="row">
          <div className="container-fluid text-left">
            <div className="my-4">
              <button onClick={() => handleCategorySelect(null)} className={`catBtns ${!selectedCategory? 'active': ''}`}>Select All</button>
              {data.categories.data.map(category => (
                <button key={category.id} onClick={() => handleCategorySelect(category)} className={`catBtns ${selectedCategory && selectedCategory.id == category.id? 'active': ''}`}>
                  {category.attributes.Category}
                </button>
              ))}
            </div>
            <div className="row">
              {selectedCategory ? (
                  sortedCategories(selectedCategory).map(article => (
                        <div className="col-md-4 my-3" key={article.id}>
                          <img
                            className="img-rounded blog-imgs"
                            src={article.attributes.CoverImage?.data?.attributes?.url}
                            alt="Card cap"
                          />
                          <p className="category-text">{selectedCategory.attributes.Category}</p>
                          <p className="article-tilte">{article.attributes.Title}</p>
                          <p className="article-date">{article.attributes.Date}</p>
                          <p className="article-desct">{truncateText(article.attributes.Description, 100)}</p>
                          <Link to={"/posts/" + article.id} style={{ textDecoration: "none" }}>
                            <p className="article-read-more">
                              Read more
                            </p>
                          </Link>
                        </div>
                  ))
              ) : (
                  data.categories.data.map(category => (
                    fromHomePage ? 
                      sortedCategoryArticles(category).slice(0, 1).map(article => (
                        <div className="col-md-4 my-3" key={article.id}>
                          <img
                            className="img-rounded blog-imgs"
                            src={article.attributes.CoverImage?.data?.attributes?.url}
                            alt="Card cap"
                          />
                          <p className="category-text">{category.attributes.Category}</p>
                          <p className="article-tilte">{article.attributes.Title}</p>
                          <p className="article-date">{article.attributes.Date}</p>
                          <p className="article-desct">{truncateText(article.attributes.Description, 100)}</p>
                          <Link to={"/posts/" + article.id} style={{ textDecoration: "none" }}>
                            <p className="article-read-more">
                              Read more
                            </p>
                          </Link>
                        </div>
                      ))
                    :
                      sortedCategoryArticles(category).map(article => (
                        <div className="col-md-4 my-3" key={article.id}>
                          <img
                            className="img-rounded blog-imgs"
                            src={article.attributes.CoverImage?.data?.attributes?.url}
                            alt="Card cap"
                          />
                          <p className="category-text">{category.attributes.Category}</p>
                          <p className="article-tilte">{article.attributes.Title}</p>
                          <p className="article-date">{article.attributes.Date}</p>
                          <p className="article-desct">{truncateText(article.attributes.Description, 100)}</p>
                          <Link to={"/posts/" + article.id} style={{ textDecoration: "none" }}>
                            <p className="article-read-more">
                              Read more
                            </p>
                          </Link>
                        </div>
                      ))
                  ))
              )}
            </div>
          </div>
        </div>
        <div className="row">
          {fromHomePage && <Link to={"/blog"} style={{ textDecoration: "none" }}>
            <p className="text-left pt-3">
              Visit our Blog page for more blogs.
            </p>
          </Link>}
        </div>
      </div>
    </section>
  );
}

export default Blog;

import React from "react";
import "./style.css";
import Modal from "react-bootstrap/Modal";
import closeIcon from "./Assets/close_icon.svg";
import linkedin from "./Assets/linkedinPop.svg";

function Popup({ show, onHide, teamInfo }) {
  if (Object.entries(teamInfo).length === 0) return null;

  return (
    <Modal
      show={show}
      onHide={onHide}
      // className="opacity-25"
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="container-fluid" style={{backgroundColor:'#00022a', color:'white', borderRadius:'13px'}}>
        <div className="row justify-content-end">
          <div className="col-2">
            <img
              src={closeIcon}
              alt="close button"
              onClick={onHide}
              style={{ width: "32px", height: "32px", cursor: "pointer", borderRadius:'50%', filter:'invert(1)', border:'1px solid black', padding:'7px' }}
            />
          </div>
        </div>
        <div className="col py-5" style={{alignItems:'center', display:'flex', flexDirection:'column', width:'min(300px, 80vw)', margin:'auto'}}>
          <div className="col-5" style={{maxWidth:"unset"}}>
            <img
              className="team_picture img-fluid"
              src={`${teamInfo.picture?.data.attributes.url}`}
              alt="profile-pic"
            />
          </div>
          <div style={{height:'1.5em'}} />
          <div className="col">
<div className="row" style={{alignItems:'center'}}>
  <div className="col">            
  <h5 className="team-member-name">{teamInfo.name}</h5>
  <div className="team-member-job">{teamInfo.jobTitle}</div>
  </div>
<a
              href={teamInfo.linkedin}
              target="_blank"
              rel="noopener noreferrer"
              style={{borderRadius:'50%', padding:'1px', border:'solid 1px white'}}
            >
              <img src={linkedin} width='32px' alt="linkedin" />
            </a>
</div>


<div style={{height:'1em'}} />
            <p className="team-member-bio">{teamInfo.bio}</p>

          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default Popup;

import React from 'react'
import './style.css'


export default function TitleBanner({title}) {
  return (
    <div className='section-title-side-banner'>
        <div className='section-title-side-banner-text'>
            <div style={{width:'fit-content'}}>
                {title}
            </div>
        </div>
    </div>
  )
}

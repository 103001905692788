import React, { useEffect } from "react";
import "./style.css";
import ContactForm from "../../components/Contact Form/ContactForm";
import { Helmet } from "react-helmet-async";

function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Contact</title>
        <meta
          name="description"
          content="We are looking forward to hearing from you. If you have any questions or inquires don’t hesitate to send us a message. We would love to hear from you."
        />
        <link rel="canonical" href="/contact" />
      </Helmet>
      <div className="container-fluid contact_page py-5 m-0">
        <div className="row justify-content-center mb-3 mb-xl-5">
          <div className="col-11 col-md-10 text-light pt-5">
            <h1 className="main_title display-3">Get in touch</h1>
            <h3 className="second_title">
              We are looking forward to hearing from you
            </h3>
          </div>
        </div>

        <div className="row d-flex justify-content-between h-100">
          <div className="col-11 col-md-10 col-xl-2 text-left text-light align-self-start mb-2 mb-xl-0 py-2 py-xl-5 mb-5 mb-xl-0 mx-auto">
            <h2 className="py-5">Questions?</h2>
            <p>
              If you have any questions or inquires don’t hesitate to send us a
              message. We would love to hear from you.
            </p>
          </div>
          <div className="col-11 col-md-8 col-xl-5 mx-auto contact_page_form_section">
            <ContactForm />
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;

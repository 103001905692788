import React from "react";
import "./style.css";
import ContactForm from "../../../components/Contact Form/ContactForm";
import ParticlesAnimation from "../../../components/Particles animation/ParticlesAnimation";

function Contact() {
  return (
    <section
      style={{position:'relative'}}
      className="container-fluid contact_section justify-content-center"
      id="contact-section"
    >
            <ParticlesAnimation id='p-contact' />
      <div className="row h-100 ">
        <div className="col-11 col-md-7 col-lg-6 col-xl-5 mx-auto my-auto rounded">
          <ContactForm />
        </div>
      </div>
    </section>
  );
}

export default Contact;

import React, { useEffect } from 'react'
import linkedin from "../../../components/Popup/Assets/linkedinPop.svg";
import closeIcon from "../../../components/Popup/Assets/close_icon.svg";

export default function HoverCard({ teamInfo, opacity }) {
  return (
    <div className='card-content' style={{ backgroundColor: '#00022a', color: 'white', borderRadius: '13px', opacity: `${opacity}`, ...(opacity == 1 && { transform: 'scale(1.1)' }) }}>
      {/* <div className="row-2" style={{justifyContent:'end', display:'flex', marginTop:'1em', marginRight:'1em'}}>
            <img
              src={closeIcon}
              alt="close button"
              onClick={()=>{}}
              style={{ width: "32px", height: "32px", cursor: "pointer", borderRadius:'50%', filter:'invert(1)', border:'1px solid black', padding:'7px' }}
            />
          </div> */}
      <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', width: '270px', margin: 'auto' }}>

        <div className='sqr-img-container'>
          <img src={teamInfo.picture?.data.attributes.url} />
        </div>

        <div style={{ height: '1.5em' }} />
        <div className="col">
          <div style={{ alignItems: 'start', display: 'flex' }}>
            <div className="col">
              <h5 className="team-member-name">{teamInfo.name}</h5>
              <div className="team-member-job">{teamInfo.jobTitle}</div>
            </div>
            <a
              href={teamInfo.linkedin}
              target="_blank"
              rel="noopener noreferrer"
              style={{ borderRadius: '50%', padding: '1px', border: 'solid 1px white' }}
            >
              <img src={linkedin} style={{ pointerEvents: 'all' }} width='32px' alt="linkedin" />
            </a>
          </div>
          <div style={{ height: '1em' }} />
          <p className="team-member-bio">{teamInfo.bio}</p>
        </div>
      </div>
    </div>
  )
}
